'use client';

import React, { useTransition, useState } from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useRouter, usePathname } from '../../../i18n/routing';
import { LOCALES } from '../../../constants/locale';
import { Locale } from '../../../types/locale';

export const LanguageSwitcher = ({ locale }: { locale: string }) => {
    const router = useRouter();
    const [isPending, startTransition] = useTransition();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const pathname = usePathname();

    const onClick: MenuProps['onClick'] = (e) => {
        startTransition(() => {
            router.replace(pathname as any, { locale: e.key });
        });
    };

    const onOpen = (open: boolean) => setMenuOpen(open);

    const menuProps = {
        items: Object.entries(LOCALES).map(([ key, label ]) => ({ key, label })),
        selectable: true,
        defaultSelectedKeys: [locale],
        onClick
    };

    return (
        <Dropdown
            menu={menuProps}
            disabled={isPending}
            trigger={['click']}
            onOpenChange={onOpen}
        >
            <button className="b-lang-button">
                <span>{LOCALES[locale as Locale]}</span>
                {menuOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </button>
        </Dropdown>
    );
};
