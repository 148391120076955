import { Locale } from '../types/locale';

export const DEFAULT_LOCALE = Locale.en;
export const ALLOWED_LOCALES = [Locale.en, Locale.ru, Locale.de, Locale.it, Locale.es, Locale.fr] as const;

export const LOCALES = {
    [Locale.en]: 'En',
    [Locale.ru]: 'Ru',
    [Locale.de]: 'Ge',
    [Locale.it]: 'It',
    [Locale.es]: 'Es',
    [Locale.fr]: 'Fr',
};
