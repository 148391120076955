'use client';

import React from 'react';
import { useSelectedLayoutSegment } from 'next/navigation';
import dynamic from 'next/dynamic';
import { Link } from '../../../i18n/routing';

type HeaderMenuProps = {
    locale: string;
    linkConfig: { path: string, title: string }[];
};

const AuthLinks = dynamic(() => import('./HeaderAuthLinks'), { ssr: false });

export const HeaderMenu = ({
    locale,
    linkConfig
}: HeaderMenuProps) => {
    const selectedLayoutSegment = useSelectedLayoutSegment();
    const pathname = selectedLayoutSegment || '';
    const url = pathname === '(main)' ? '' : pathname;

    return (
        <div className="b-header__nav">
            <nav>
                <ul className="b-header__nav__list">
                    {linkConfig.map(({ path, title }) => (
                        <li key={path}>
                            <Link href={`/${path}` as any} className={path === url ? 'active' : ''}>{title}</Link>
                        </li>
                    ))}
                    <AuthLinks locale={locale} />
                </ul>
            </nav>
        </div>
    );
}
