'use client'

import React, { FC, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelectedLayoutSegment } from 'next/navigation';
import { Link } from '../../../i18n/routing';

type HeaderMenuMobileProps = {
    locale: string;
    linkConfig: { path: string, title: string }[];
};

const AuthLinks = dynamic(() => import('./HeaderAuthLinks'), { ssr: false });

export const HeaderMobileMenu: FC<HeaderMenuMobileProps> = ({
    locale,
    linkConfig
}) => {
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
    const selectedLayoutSegment = useSelectedLayoutSegment();
    const pathname = selectedLayoutSegment || '';

    return (
        <>
            <div className="b-header__menu-open" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <img
                    src="/images/menu-open.svg"
                    className="img-default"
                    alt=""
                />
            </div>
            {showMobileMenu ? (
                <div className="menu-mobile">
                    <div className="menu-mobile__inner">
                        <div className="menu-mobile__header">
                            <div className="menu-mobile__header__avatar">
                                <img className="img-default" src="/images/avatar.png" alt=""/>
                            </div>
                            <ul className="menu-mobile__header__nav">
                                <AuthLinks
                                    separatorClass="menu-mobile__header__nav__line"
                                    locale={locale}
                                />
                            </ul>
                        </div>
                        <div className="menu-mobile__body">
                            <ul className="menu-mobile__list">
                                {linkConfig.map(({ path, title }) => (
                                    <li key={path}>
                                        <Link href={`/${path}` as any} className={pathname === path ? 'active' : ''}>{title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
